<template>
  <v-app>
    <div style="position: fixed; height: 100vh; background-color: #222">
      <v-img src="@/assets/login.jpg" width="100vw" height="100vh" />
    </div>
    <v-container id="fundo" class="p-0 fill-height" fluid>
      <v-row class="fill-height" align="center" justify="center">
        <v-col cols="11" sm="8" md="5" lg="4" style="padding: 0px">
          <v-container :style="$vuetify.breakpoint.xs ? 'padding: 4px' : ''">
            <v-card color="white" style="padding: 12px">
              <v-container class="pb-2">
                <v-row
                  class="layout-logos-login fill-height px-4 height"
                  align="center"
                  justify="center"
                >
                  <v-img
                    class="logo-form-login educampo"
                    :src="logo"
                    height="120px"
                    contain
                  />
                </v-row>
              </v-container>
              <div v-if="!loader" align="center" justify="center">
                <h2 class="font-weight-regular">Escolha de perfil</h2>

                <v-col class="d-flex" cols="12" sm="6">
                  <v-select
                    v-model="selectedRole"
                    :items="items"
                    label="Escolha seu perfil"
                  ></v-select>
                </v-col>

                <v-col>
                  <v-btn color="success" @click="updateRoles">Confirmar</v-btn>
                </v-col>

                <v-col align="left">
                  <router-link to="/login">
                    <v-btn text color="blue"> Voltar ao login </v-btn>
                  </router-link>
                </v-col>
              </div>
              <v-container v-else class="pa-6">
                <v-row justify="space-around">
                  <v-progress-circular
                    :size="100"
                    :width="7"
                    color="primary"
                    :indeterminate="true"
                  />
                </v-row>
              </v-container>
            </v-card>
          </v-container>
        </v-col>
      </v-row>
      <v-snackbar
        v-model="snackbar"
        multi-line
        top
        :timeout="13500"
        color="error"
      >
        {{ message }}
        <v-btn dark text @click="snackbar = false"> Fechar </v-btn>
      </v-snackbar>
    </v-container>
  </v-app>
</template>
<script>
import { ENV_APP } from '../../env'
export default {
  props: {
    backgroundImage: {
      type: String,
      default: 'static/img/login.jpg',
    },
  },
  data() {
    return {
      loader: false,
      snackbar: false,
      items: [],
      selectedRole: '',
      message: '',
    }
  },

  computed: {
    logo() {
      return 'img/' + `${ENV_APP.imgFileLogin}`
    },
  },

  created() {
    this.loadRoles()
  },

  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },

    loadRoles() {
      const roles = JSON.parse(localStorage.getItem('roles'))
      this.items = roles.map(r => this.capitalizeFirstLetter(r.slug))
    },

    updateRoles() {
      const role = this.selectedRole.toLowerCase()
      if (role && role != '' && role.length > 0) {
        const user = JSON.parse(localStorage.getItem('user'))

        user.role = role

        localStorage.setItem('user', JSON.stringify(user))
        this.$user.set(user)

        this.$router.push({ path: 'dashboard' })
      }
    },
  },
}
</script>
<style scope lang="scss">
.outline-login .container .v-input .v-input__control .v-input__slot {
  border: 2px solid #005bb9;
}

.logo-form-login.educampo .v-image__image--cover {
  background-size: contain;
  background-position: center !important;
}

.logo-form-login.sebrae .v-image__image--cover {
  background-size: contain;
  background-position: center !important;
}

html {
  overflow: auto;
}

.headline {
  font-family: 'Poppins', sans-serif !important;
}

.display-grid {
  display: grid;
  height: auto !important;
  .flex {
    background-color: white;
    padding: 7px;
    margin-bottom: 70%;
    .v-responsive.v-image {
      margin-top: 6px;
      padding: 0px 0.7px;
    }
  }
}

.apresentacao {
  font-size: 24px;
  font-family: 'Poppins', sans-serif;
}

.layout-login-row {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.demonstracao span {
  font-size: 1rem !important;
  font-weight: bold;
  letter-spacing: 0.009375em !important;
  line-height: 1.357rem;
  font-family: 'Roboto', sans-serif !important;
  color: #fff;
}

.requisitar-demonstracao {
  font-size: 1.2rem !important;
  font-weight: bold;
  letter-spacing: 0.009375em !important;
  line-height: 1.75rem;
  font-family: 'Roboto', sans-serif !important;
  color: #4e5152;
}

@media (min-width: 41em) {
  #fundo {
    background-size: auto;
  }
} /* 656px */

@media (min-width: 47em) {
  #fundo {
    background-size: auto;
  }
} /* 752px */

@media (min-width: 68em) {
  #fundo {
    background-size: auto;
  }
} /* 1088px */

@media (min-width: 82em) {
  #fundo {
    background-size: auto;
  }
} /* 1312px */

@media (min-width: 100em) {
  #fundo {
    background-size: contain;
  }
  .layout-logos-login {
    align-items: center !important;
  }
  .tela-login.flex .v-card {
    height: 316px;
  }
  .headline {
    font-size: 34px !important;
  }
  .logo-form-login.educampo {
    height: 110px !important;
  }
  .logo-form-login.sebrae {
    height: 70px !important;
    padding-bottom: 20px;
  }
} /* 1600px */
</style>
